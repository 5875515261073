import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

export class MessagePageTemplate extends React.Component {
  render() {
    const { heading, subheading, button } = this.props;

    return (
      <div
        style={{
          backgroundColor: 'transparent',
          color: 'white',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            height: '400px',
            width: '640px',
            textAlign: 'left',
            backgroundImage: 'linear-gradient(0deg, #7F5692 0%, #DC6E69 100%)',
            borderRadius: '4px'
          }}
        >
          <div
            style={{
              fontSize: '50px',
              fontWeight: '600',
              marginTop: '60px',
              marginLeft: '60px',
              marginBottom: '55px'
            }}
          >
            {heading}
          </div>
          <div
            style={{
              fontSize: '24px',
              lineHeight: '36px',
              marginLeft: '60px',
              marginRight: '60px',
              marginBottom: '20px',
              height: '110px'
            }}
          >
            {' '}
            {subheading}
          </div>
          <a style={{ color: 'inherit' }} href="/contact-us">
            <div
              style={{
                fontSize: '24px',
                lineHeight: '36px',
                marginLeft: '60px',
                marginRight: '60px',
                textDecoration: 'underline'
              }}
            >
              {' '}
              {button}
            </div>
          </a>
        </div>
      </div>
    );
  }
}

const MessagePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <MessagePageTemplate
      heading={frontmatter.heading}
      subheading={frontmatter.subheading}
      button={frontmatter.button}
    />
  );
};

MessagePage.propTypes = {
  heading: PropTypes.string
};

export default MessagePage;

export const messagePageQuery = graphql`
  query MessagePage {
    markdownRemark(frontmatter: { templateKey: { eq: "message-page" } }) {
      frontmatter {
        heading
        subheading
        button
      }
    }
  }
`;
